/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:8d59a58c-60e7-4036-bda8-3aeda5ccd214",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_l4HNuCHWC",
    "aws_user_pools_web_client_id": "7ngqlt27hgpnl7pjps2268og2a",
    "oauth": {
        "domain": "onlinecv5261e8c4-5261e8c4-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.onlinecv.app/home/",
        "redirectSignOut": "https://www.onlinecv.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
